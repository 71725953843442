<template>
    <div class="action-request-refund">
        <template v-if="isAgentUser">
            <p class="title">
                {{ $nameOrNick(chat.user) }}님이 친구와 만남을<br />
                가지지 못했다고 하네요 😢
            </p>

            <div class="body">
                <p class="m-b-8">상세 내용을 확인한 후 응답해주세요!</p>
                <p class="m-b-20">
                    (24시간 이내 응답 처리를 하지 않으면 만나지 못한 것으로 간주하여, {{ $nameOrNick(chat.user) }}님에게
                    소개팅권을 환급해드립니다.)
                </p>

                <button class="btn btn-primary" @click="onClickCheck" :disabled="disabled">상세 내용 확인</button>
            </div>
        </template>

        <template v-else>
            <p class="title">환급 신청 완료!</p>

            <p class="body">
                환급 신청 접수가 완료되었어요.<br />
                주선자의 확인이 완료되면, 푸시/알림으로 안내드립니다. (최대 3영업일 소요)
            </p>
        </template>
    </div>
</template>
<script>
export default {
    name: 'ActionRequestRefund',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        isAgentUser() {
            return this.chat.introduce.user_id === this.$store.getters.me.id
        },
        acceptStatus() {
            return this.chat.introduce.request.accept_status
        },
        disabled() {
            return ['refund_accept', 'refund_reject'].includes(this.acceptStatus)
        },
    },
    methods: {
        onClickCheck() {
            this.$stackRouter.push({
                name: 'RealFriendMeetingRefundCheckPage',
                props: {
                    chat: this.chat,
                },
            })
        },
        checkStatus() {
            // 이미 처리 한 경우 제외
            if (this.disabled) return

            // 처리 안했는데 이 컴포넌트가 보였다는건 유저가 환급 신청한 경우임, 근데 status가 refund가 아니라는건 클라에서 아직 업데이트 안된거
            if (this.acceptStatus !== 'refund') {
                const chat = { ...this.chat }
                chat.introduce.request.accept_status = 'refund'
                this.$store.commit('updateChatAndChats', chat)
            }
        },
    },
    mounted() {
        this.checkStatus()
    },
}
</script>

<style lang="scss" scoped>
.action-request-refund {
    .btn {
        width: 100%;
    }
}
</style>
